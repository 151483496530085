import CustomEvents from "./CustomEvents";

export default class GA4Manager extends CustomEvents {
  static manager = null;

  constructor(gaCode, { gaConfig, timeout, debug } = {}) {
    super();
    this.gaCode = gaCode;
    this.gaConfig = gaConfig || {};
    this.timeout = timeout || 5000;
    this.debug = debug;

    this.scriptSyncId = "ga4ReactScriptSync";
    this.scriptAsyncId = "ga4ReactScriptAsync";

    this.onReady = this.initialize();
  }

  /**
   * output on resolve initialization
   */
  outputOnResolve() {
    return {
      pageview: this.pageview,
      event: this.event,
      gtag: this.gtag,
      events: this.events,
      vitals: this.vitals,
    };
  }

  /**
   * @desc Upload script and return main function for send ga4 events, pageview etc
   * @returns {Promise}
   */
  initialize() {
    return new Promise((resolve, reject) => {
      if (GA4Manager.isInitialized()) {
        return resolve(GA4Manager.manager);
      }

      // in case of retry logics, remove previous scripts
      const previousScriptAsync = document.getElementById(this.scriptAsyncId);
      if (previousScriptAsync) {
        previousScriptAsync.remove();
      }

      const head = document.getElementsByTagName("head")[0];
      const scriptAsync = document.createElement("script");
      scriptAsync.setAttribute("id", this.scriptAsyncId);
      scriptAsync.setAttribute("async", "");

      scriptAsync.setAttribute(
        "src",
        `https://www.googletagmanager.com/gtag/js?id=${this.gaCode}`
      );

      scriptAsync.onload = () => {
        const target = document.getElementById(this.scriptSyncId);
        if (target) {
          target.remove();
        }

        // in case of retry logics, remove previous script sync
        const previousScriptSync = document.getElementById(this.scriptSyncId);
        if (previousScriptSync) {
          previousScriptSync.remove();
        }

        const scriptSync = document.createElement("script");
        scriptSync.setAttribute("id", this.scriptSyncId);

        let scriptHTML = `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);};
        gtag('js', new Date());
        gtag('config', '${this.gaCode}', ${JSON.stringify(this.gaConfig)});`;

        scriptSync.innerHTML = scriptHTML;

        head.appendChild(scriptSync);

        GA4Manager.manager = this.outputOnResolve();
        resolve(GA4Manager.manager);
      };

      scriptAsync.onerror = (event) => {
        if (typeof event === "string") {
          reject(`GA4Manager intialization failed ${event}`);
        } else {
          const error = new Error();
          error.name = "GA4Manager intialization failed";
          error.message = JSON.stringify(event, [
            "message",
            "arguments",
            "type",
            "name",
          ]);
          reject(error);
        }
      };

      const onChangeReadyState = () => {
        switch (document.readyState) {
          case "interactive":
          case "complete":
            if (!GA4Manager.isInitialized()) {
              head.appendChild(scriptAsync);
              document.removeEventListener(
                "readystatechange",
                onChangeReadyState
              );
            }
            break;
          default:
            break;
        }
      };

      if (document.readyState === "complete") {
        onChangeReadyState();
      } else {
        document.addEventListener("readystatechange", onChangeReadyState);
      }

      setTimeout(() => {
        reject(new Error("GA4Manager Timeout"));
      }, this.timeout);
    });
  }

  /**
   * @desc send pageview event to gtag
   */
  pageview(path, title, location) {
    const origin = window?.location?.origin;
    let pageLocation = location || (origin ? origin + path : path);

    return this.events.PAGE_VIEW({
      page_path: path,
      page_location: pageLocation,
      page_title: title || document.title,
    });
  }

  vitals(data) {
    this.event("VITALS", data);
  }

  /**
   * @desc set event and send to gtag
   * @param {String} action
   * @param {Object} payload
   * @returns {Promise}
   */
  event(action, ...args) {
    return this.onReady
      .then(() => {
        if (this.debug) {
          console.info(`> Gtag Event: ${action}`, ...args);
        }

        return this.gtag("event", action, ...args);
      })
      .catch((error) => console.error(`> Gtag Event error: `, error));
  }

  gtag(...args) {
    return window.gtag(...args);
  }

  static isInitialized() {
    return Boolean(GA4Manager.manager);
  }

  async getGA4Manager() {
    if (GA4Manager.isInitialized()) {
      return GA4Manager.manager;
    }
    await this.initialize();
    return GA4Manager.manager;
  }
}
