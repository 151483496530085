import React from 'react'
import { useField } from 'formik'
import PropTypes from 'prop-types'

const Input = ({ label, className, inputClassName, optional, onChange: onInputChange, validate, ...props }) => {
  const [field, meta] = useField({ ...props, validate })
  let error = null

  if (meta.touched && meta.error) {
    error = meta.error
  }

  const { onChange: onFieldChange, ...fieldRest } = field

  const onChange = e => {
    // should work as a middleware with no side-effects (if used responsibly)
    if (typeof onInputChange === 'function') {
      onInputChange(e)
    }
    onFieldChange(e)
  }

  return (
    <div className={`flex flex-col mb-2 ${className}`}>
      <div className="flex flex-row justify-between items-end mb-1">
        <label htmlFor={props.name}>{label}</label>
        {optional && <span className="text-xs">Optional</span>}
      </div>
      <input
        className={`rounded-t bg-lightGray h-10 border-b border-darkGray focus:border-primary px-4 ${inputClassName}`}
        id={props.name}
        {...props}
        {...fieldRest}
        {...{ onChange }}
      />
      <span className="text-error text-xs h-4">{error}</span>
    </div>
  )
}

Input.propTypes = {
  className: PropTypes.string,
  validate: PropTypes.func,
}

Input.defaultProps = {
  className: '',
  inputClassName: '',
}

export default Input
