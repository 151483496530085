import React from 'react'
import Hero from 'components/Hero'
import HowItWorks from 'components/HowItWorks'
import QualityDemographics from 'components/QualityDemographics'
import KeyInsights from 'components/KeyInsights'
import Pricing from 'components/Pricing'
import FAQ from 'components/FAQ'
import TryItNow from 'components/TryItNow'

function Home() {
  return (
    <div>
      <Hero />
      <HowItWorks />
      <QualityDemographics />
      <KeyInsights />
      <Pricing />
      <FAQ />
      <TryItNow />
    </div>
  )
}

export default Home
