import React, { useState } from 'react'
import CollapsiblePanel from 'components/CollapsiblePanel'
import { Formik } from 'formik'
import Input from 'formikControls/input'
import CountrySelector from 'formikControls/countrySelector'
import StateSelector from 'formikControls/stateSelector'
import { thirdStepValidationSchema } from './validationSchemas'
import PropTypes from 'prop-types'

const INITIAL_VALUES = {
  fullName: '',
  address: '',
  country: '',
  state: '',
  city: '',
  postalCode: '',
}

const CheckoutStep3 = ({ onComplete, onEdit }) => {
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES)
  const onEditClick = newInitialValues => {
    setInitialValues(newInitialValues)
    onEdit(3)
  }

  const onSubmit = values => {
    onComplete(3, values)
  }


  // This panel must not be collapsed, as CardElement must be mounted
  // into the DOM at all times during the checkout process.
  return (
    <CollapsiblePanel
      title="3. Billing Info"
      stepNumber={3}
      onEditClick={onEditClick}
      completedKeys={{country: 'Country', postalCode: 'Postal Code'}}
    >
      <div className="flex flex-col">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={thirdStepValidationSchema}
          validateOnMount
        >
        {
          ({ handleSubmit, setFieldValue }) => {
            const onCountryChange = (v) => {
              setFieldValue('state', '')
            }
            return (
              <form onSubmit={handleSubmit}>
                <Input
                  type="text"
                  name="fullName"
                  label="Full Name"
                />
                <Input
                  type="text"
                  name="address"
                  label="Address"
                />
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <CountrySelector name="country" onChange={onCountryChange} />
                  <StateSelector name="state" />
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <Input name="city" label="City" />
                  <Input name="postalCode" label="Postal Code" inputClassName="uppercase" />
                </div>

                <div className="text-center mt-4">
                  <button type="submit" className="btn-primary px-10" >
                    Continue
                  </button>
                </div>
              </form>
            )
          }
        }
        </Formik>
      </div>
    </CollapsiblePanel>
  )
}

CheckoutStep3.propTypes = {
  onComplete: PropTypes.func,
  onEdit: PropTypes.func,
}

export default CheckoutStep3
