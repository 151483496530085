import React from 'react'
import { PRICING_FEATURES } from 'data'
import GtagLink from 'components/GtagLink'

const PriceCard = ({ children }) => (
  <div
    className={`flex flex-col items-center col-span-1 px-6 pb-8 gdShadowedBox`}
  >
    {children}
  </div>
)

const PriceCardTitle = ({ children }) => (
  <div className="flex flex-row h-16 items-end text-strongBlack">
    {children}
  </div>
)

const TypeTag = ({ text }) => (
  <div className="rounded-full h-9 bg-lightGray px-6 align-middle flex items-center my-8">
    <span className="text-strongBlack">{text}</span>
  </div>
)

const FeaturesList = ({ featureIndex }) => {
  const list = PRICING_FEATURES[featureIndex]

  return (
    <div className="w-full mb-4 flex-1">
      {list.map((feature, index) => (
        <div
          key={`${featureIndex}-${index}`}
          className="flex flex-row items-start mb-5"
        >
          <img className="mt-1" src="/img/check-circle.svg" alt="Feature" />
          <p className="ml-4">{feature}</p>
        </div>
      ))}
    </div>
  )
}

const Pricing = () => {
  return (
    <div className="section flex flex-col items-center py-14 mb-24" id="pricing">
      <h2>Pricing</h2>
      <div className="title-underline w-20" />

      <div className="grid grid-cols-1 md:grid-cols-2 max-w-2xl gap-14 mt-10">
        <PriceCard>
          <PriceCardTitle>
            <span className="align-bottom text-5xl font-bold text-strongBlack">$50</span>
            <span className="text-strongBlack">/Report</span>
          </PriceCardTitle>
          <TypeTag text="Individual" />
          <FeaturesList featureIndex={0} />
          <GtagLink id="upload-btn-pricing" to="/checkout" gtagLabel="Upload click: Pricing">
            <button className="btn-primary justify-self-end">
              Upload 1 File
            </button>
          </GtagLink>
        </PriceCard>

        <PriceCard>
          <PriceCardTitle>
            <span className="align-bottom text-2xl font-bold text-strongBlack">CONTACT US</span>
          </PriceCardTitle>
          <TypeTag text="Enterprise" />
          <FeaturesList featureIndex={1} />
          <a href="mailto:support@getdemographics.com">
            <button className="btn-primary justify-self-end">Contact Us</button>
          </a>
        </PriceCard>
      </div>
    </div>
  )
}

export default Pricing
