import React, { useState } from 'react'
import { Formik } from 'formik'
import Input from 'formikControls/input'
import CollapsiblePanel from 'components/CollapsiblePanel'
import { secondStepValidationSchema } from '../validationSchemas'

import PropTypes from 'prop-types'
import EmailInput from './EmailInput'

const INITIAL_VALUES = {
  firstName: '',
  lastName: '',
  company: '',
  demographicsUsage: '',
  industry: '',
}

const CheckoutStep2 = ({ onComplete, onEdit, existentEmail }) => {
  const [mode, setMode] = useState(Boolean(existentEmail) ? 'returning' : 'new')
  const [email, setEmail] = useState(existentEmail)
  const [initialValues, setInitialValues] = useState({...INITIAL_VALUES, email: email || ''})
  const completedKeys = {
    email: 'Email:',
  }
  if (mode === 'new') {
    completedKeys.lastName = {
      label: 'Full Name:',
      keys: ['firstName', 'lastName'],
    }
  }

  const onEditClick = (newInitialValues) => {
    setInitialValues(newInitialValues)
    onEdit(2)
  }

  const onSubmit = (values) => {
    const completeValues = {...values, email}
    onComplete(2, completeValues)
  }

  const onReturningSubmit = e => {
    e.preventDefault()
    onSubmit({ isReturningCustomer: true })
    return false
  }

  const toggleMode = () => {
    setEmail('')
    setMode(mode => mode === 'new' ? 'returning' : 'new')
  }

  return (
    <CollapsiblePanel
      title="2. User Details"
      stepNumber={2}
      completedKeys={completedKeys}
      onEditClick={onEditClick}
    >
      <div className="flex flex-col">
        {mode === 'new' && (
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={secondStepValidationSchema}
            validateOnMount
          >
            {({ handleSubmit, isValid }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Input type="text" name="firstName" label="First Name" />
                  <Input type="text" name="lastName" label="Last Name" />
                  <EmailInput mode={mode} onModeChange={toggleMode} onChange={setEmail} />
                  <Input
                    type="text"
                    name="company"
                    label="Company Name"
                    optional
                  />
                  <Input
                    type="text"
                    name="demographicsUsage"
                    label="How are you using the Demographics Data"
                    optional
                  />
                  <Input
                    type="text"
                    name="industry"
                    label="Industry"
                    optional
                  />

                  <div className="text-center mt-4">
                    <button
                      type="submit"
                      className="btn-primary px-10"
                      disabled={!isValid || !Boolean(email)}
                    >
                      Continue
                    </button>
                  </div>
                </form>
              )
            }}
          </Formik>
        )}
        {
          mode === 'returning' && (
            <form onSubmit={onReturningSubmit}>
              <EmailInput mode={mode} onModeChange={toggleMode} onChange={setEmail} initialValue={email} />
              <div className="text-center mt-4">
                <button
                  type="submit"
                  className="btn-primary px-10"
                  disabled={!Boolean(email)}
                >
                  Continue
                </button>
              </div>
            </form>
          )
        }
      </div>
    </CollapsiblePanel>
  )
}

CheckoutStep2.propTypes = {
  onComplete: PropTypes.func,
  onEdit: PropTypes.func,
}

export default CheckoutStep2
