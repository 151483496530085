import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Gtag } from "src/helpers/Gtag";

function usePageView() {
  const location = useLocation();
  const currentPath = location.pathname + location.hash + location.search;

  useEffect(() => {
    Gtag.pageview(currentPath);
  }, [currentPath]);
}

export default usePageView;
