import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { customerExists } from 'services/api'
import { isValidEmail } from '../validationSchemas'
import debounce from 'lodash.debounce'

/**
 * Formik is a bit tricky when combining different types of validation and mostly when
 * using async, debounced strategies (https://github.com/formium/formik/pull/2140)
 *
 * For this reason we'll use a control totally separated from the form and consider
 * its validity state separately.
 */

const DEBOUNCE_DELAY = 500
const EmailInput = ({ mode, onModeChange, onChange, initialValue }) => {
  const [value, setValue] = useState(initialValue)
  // const [touched, setTouched] = useState(false)
  const [error, setError] = useState(null)
  const debouncedSearch = useMemo(() => debounce(async value => {
    let errorMessage = ''
    if (isValidEmail(value)) {
      const result = await customerExists(value)
      if (mode === 'new' && result.exists) {
        errorMessage = 'Email already exists. Are you a Returning Customer?'
      } else if (mode === 'returning' && !result.exists) {
        errorMessage = 'Email not found. Are you a New Customer?'
      }
    } else {
      errorMessage = 'Please enter a valid email address'
    }
    onChange(Boolean(errorMessage) ? '' : value)
    setError(errorMessage)
    // eslint-disable-next-line
  }, DEBOUNCE_DELAY), [isValidEmail, customerExists])

  const validate = useCallback(debouncedSearch, [debouncedSearch])

  useEffect(() => {
    if (isValidEmail(initialValue)) {
      validate(initialValue)
    }
  }, [initialValue, validate])

  const onFocus = (e) => {
    setError(null)
  }
  const onValueChange = (e) => {
    onChange('')
    setError(null)
    setValue(e.target.value)
    validate(e.target.value)
  }

  return (
    <div className="flex flex-col mb-2">
      <div className="flex flex-row justify-between items-end mb-1">
        <label htmlFor='email'>Email Address</label>
        <span className="text-sm cursor-pointer" onClick={onModeChange}>
          { mode === 'new' ? 'Returning Customer?' : 'Are you a new customer?' }
        </span>
      </div>
      <input
        name="email"
        className="rounded-t bg-lightGray h-10 border-b border-darkGray focus:border-primary px-4"
        {...{value, onChange: onValueChange, onFocus }}
      />
      <span className="text-error text-xs h-4">{error}</span>
    </div>
  )
}

export default EmailInput
