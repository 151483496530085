import React from 'react'
import { useFormikContext } from 'formik'
import Select from './select'
import Input from './input'

const StateSelector = ({ name }) => {
  const { values } = useFormikContext()

  if (values.country === 'CA') {
    return (
      <Select name={name} label="State/Province">
        <option disabled value="">
          -- Select a state --
        </option>
        <option value="AB">Alberta</option>
        <option value="BC">British Columbia</option>
        <option value="MB">Manitoba</option>
        <option value="NB">New Brunswick</option>
        <option value="NL">Newfoundland and Labrador</option>
        <option value="NT">Northwest Territories</option>
        <option value="NS">Nova Scotia</option>
        <option value="NU">Nunavut</option>
        <option value="ON">Ontario</option>
        <option value="PE">Prince Edward Island</option>
        <option value="QC">Quebec</option>
        <option value="SK">Saskatchewan</option>
        <option value="YT">Yukon</option>
      </Select>
    )
  }

  return <Input type="text" name={name} label="State/Province" />
}

export default StateSelector
