import React, { useContext } from 'react'
import { CheckoutContext } from 'containers/Buy'
import { isObject } from 'helpers/js'

const CollapsiblePanel = ({ title, stepNumber, onEditClick, completedKeys, children }) => {
  const { checkoutData } = useContext(CheckoutContext)
  const stepValues = checkoutData[stepNumber] || {}
  const completed = stepValues._state === 'completed'
  const editing = stepValues._state === 'editing'

  const onEdit = () => {
    onEditClick(stepValues)
  }

  const renderCompleted = () => {
    return (
      <div className="flex flex-row bg-snow px-3 py-5 justify-between items-center">
        <div>
          {
            Object.keys(completedKeys).map((k, ix) => {
              let value = stepValues[k]
              let label = completedKeys[k]
              if (isObject(label)) {
                value = label.keys.reduce((v, lk) => v += ` ${ stepValues[lk]}`, '')
                label = label.label
              }

              return (
                <div key={`_${stepNumber}_c_${ix}`}>
                  <span className="font-bold">{label}</span><span>{` ${value}`}</span>
                </div>
              )
            })
          }
        </div>
        <button className="btn-outline w-20" onClick={onEdit}>Edit</button>
      </div>
    )
  }

  return (
    <div className="w-full rounded overflow-hidden mb-6 gdShadow">
      <div className="p-3 leading-relaxed bg-strongBlack flex flex-row justify-between">
        <span className="text-white font-bold text-xl">{title}</span>
        { completed && <img src="/img/check.svg" alt="Completed" /> }
      </div>
      {
        (completed && completedKeys) && renderCompleted()
      }
      {
        (editing || !completedKeys) &&
        <div className="px-10 py-6 bg-snow">
          {children}
        </div>
      }
    </div>
  )
}

export default CollapsiblePanel
