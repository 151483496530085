import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { FAQ as FAQData } from 'data'

const FAQHeading = ({ title }) => {
  return (
    <AccordionItemHeading>
      <AccordionItemButton>
        <div className="py-2.5 border-b border-lightGray flex flex-row items-center">
          <div
            className="accordion-caret h-8 w-8 bg-center bg-no-repeat"
            style={{ backgroundImage: 'url("/img/chevron.svg")' }}
          />
          <span className="text-xl text-strongBlack">{title}</span>
        </div>
      </AccordionItemButton>
    </AccordionItemHeading>
  )
}

const FAQ = () => {
  return (
    <div className="w-full bg-snow py-16 px-20" id="faq">
      <div className="section flex flex-col items-center">
        <h2>Frequently Asked Questions</h2>
        <div className="title-underline w-80 mb-10" />

        <Accordion allowZeroExpanded className="w-full max-w-3xl mb-10">
          {FAQData.map(({ title, description }, index) => (
            <AccordionItem key={`faq-${index}`}>
              <FAQHeading title={title} />
              <AccordionItemPanel>
                <p className="py-4 px-2 text-xl leading-8">{description}</p>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>

        <span>
          Still have questions? Get in touch with us at:{' '}
          <a href="mailto:support@getdemographics.com" className="text-primary">
            support@getdemographics.com
          </a>
        </span>
      </div>
    </div>
  )
}

export default FAQ
