import React from 'react'
import { HOW_IT_WORKS } from 'data'

const HIWItem = ({ item }) => {
  return (
    <div className="p-10 grid grid-cols-1 md:grid-cols-2">
      <div className="flex flex-row">
        <span className="mt-3 w-10 h-10 bg-primary text-white font-bold flex-shrink-0 text-2xl flex items-center justify-center rounded-full">
          {item.number}
        </span>
        <div className="flex flex-col ml-4">
          <h3>{item.title}</h3>
          <p className="mt-4">{item.description}</p>
        </div>
      </div>
      <div className="flex flex-row items-center justify-center max-w-full">
        <img alt={item.imgAlt} src={`/img/${item.image}`} />
      </div>
    </div>
  )
}

const HowItWorks = () => {
  return (
    <div className="section flex flex-col items-center" id="how-it-works">
      <h2>How it works</h2>
      <div className="title-underline w-28" />
      {HOW_IT_WORKS.map((item, index) => (
        <HIWItem key={`hiw-${index}`} item={item} />
      ))}
    </div>
  )
}

export default HowItWorks
