import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className="py-5 bg-strongBlack">
      <div className="section">
        <div className="grid grid-cols-1 md:grid-cols-2 text-white">
          <div className="flex flex-col items-center md:items-start text-xs">
            <Link to="/">
              <img
                className="mb-2"
                src="/img/logo-dark.svg"
                alt="Get Demographics"
              />
            </Link>
            <span className="text-white font-bold">Contact Us:</span>
            <span className="text-white">393 University Ave, Suite 1812</span>
            <span className="text-white">Toronto, ON M5G 1E6</span>
            <span className="text-white">
              <a href="mailto:support@getdemographics.com">
                support@getdemographics.com
              </a>
            </span>
          </div>
          <div className="flex flex-col items-center md:items-end text-xs justify-end">
            <div className="flex-1 flex items-center my-6">
              <Link to="/privacy-policy" className="mr-6">Privacy Policy</Link>
              <Link to="/tos">TOS</Link>
            </div>
            <a href="http://livegauge.com">
              <img
                src="/img/powered-by-livegauge.svg"
                alt="Powered By LiveGauge"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
