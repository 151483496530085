import React, { useEffect, useState } from 'react'
import { Elements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import CheckoutStep1 from './CheckoutStep1'
import CheckoutStep2 from './CheckoutStep2'
import CheckoutStep3 from './CheckoutStep3'
import api from 'services/api'
import mixpanel from 'helpers/mixpanel'
import { isArray } from 'helpers/js'
import { useLocation } from 'react-router-dom'
import { Gtag } from 'helpers/Gtag'

const EMPTY_SUMMARY_DATA = {
  currency: '',
  subtotal: '-',
  tax: '-',
  total: '-',
}


export const CheckoutContext = React.createContext({})
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const generateCSVUploadBody = checkoutData => {
  const data = Object.assign(
    {},
    { ...checkoutData['1'] },
    { userData: { ...checkoutData['2'] } },
    { billingInfo: { ...checkoutData['3'] } }
  )

  delete data._state
  delete data.userData._state
  delete data.billingInfo._state

  return data
}

const Buy = () => {
  const queryParams = new URLSearchParams(useLocation().search)
  const [loading, setLoading] = useState(false)
  const [paymentSessionData, setPaymentSessionData] = useState({})
  const [error, setError] = useState(null)
  const [summaryData, setSummaryData] = useState(EMPTY_SUMMARY_DATA)
  const [checkoutData, setCheckoutData] = useState({
    1: { _state: 'editing' },
    2: { _state: null },
    3: { _state: null },
  })
  const stripe = useStripe()
  const existentEmail = queryParams.get('email')

  const canCheckout =
    stripe &&
    Object.keys(checkoutData).reduce(
      (mem, k) => mem && checkoutData[k]._state === 'completed'
    )
  const onCompleteStep = (stepNumber, stepData) => {
    // do not send csv file content
    const { csvData, ...mpData } = stepData

    mixpanel.track(`Checkout Step ${stepNumber}`, {
      ...mpData,
      recordCount: isArray(csvData) && csvData.length
    })

    Gtag.events.CHECKOUT("GET_DEMOGRAPHICS", "IN_PROGRESS", {
      step: stepNumber,
      ...mpData,
      recordCount: isArray(csvData) && csvData.length
    })
    const nextState = {
      ...checkoutData,
      [stepNumber]: { ...stepData, _state: 'completed' },
    }
    if (
      checkoutData[stepNumber + 1] &&
      checkoutData[stepNumber + 1]._state === null
    ) {
      nextState[stepNumber + 1]._state = 'editing'
    }

    setCheckoutData(nextState)
  }
  const onEditStep = stepNumber => {
    setCheckoutData({
      ...checkoutData,
      [stepNumber]: {
        ...checkoutData[stepNumber],
        _state: 'editing',
      },
    })
    setSummaryData(EMPTY_SUMMARY_DATA)
  }

  useEffect(() => {
    async function createPaymentSession() {
      const response = await api.createPaymentSession({
        userData: checkoutData['2'],
        billingInfo: checkoutData['3'],
      })
      setPaymentSessionData(response)

      if (!response.error) {
        const { currency, subtotal, tax } = response.priceData
        const formattedCurrency = currency.toUpperCase()
        setError(null)
        setSummaryData({
          subtotal: `${formattedCurrency} ${(subtotal / 100).toFixed(2)}`,
          tax: `${formattedCurrency} ${(tax / 100).toFixed(2)}`,
          total: `${formattedCurrency} ${((subtotal + tax) / 100).toFixed(2)}`,
        })
      } else {
        setError(response.error)
      }
      setLoading(false)
    }

    if (canCheckout) {
      setLoading(true)
      createPaymentSession()
    }
    // eslint-disable-next-line
  }, [canCheckout])

  const doCheckout = async () => {
    mixpanel.track('Checkout', checkoutData['2'])

    Gtag.events.CHECKOUT("GET_DEMOGRAPHICS", "SUMMARY", checkoutData['2'])
    setLoading(true)

    // 1. upload file to S3
    // 2. redirect to stripe checkout
    const uploadResponse = await api.uploadCSV(
      paymentSessionData.orderNumber,
      generateCSVUploadBody(checkoutData)
    )

    if (uploadResponse.error) {
      setError(uploadResponse.error)
      setLoading(false)
    } else {
      stripe.redirectToCheckout({ sessionId: paymentSessionData.sessionId })
    }

    setLoading(false)
  }

  return (
    <CheckoutContext.Provider value={{ checkoutData }}>
      <div className="section mb-16">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="w-full col-span-1 md:col-span-2">
            <CheckoutStep1 onComplete={onCompleteStep} onEdit={onEditStep} />
            <CheckoutStep2 onComplete={onCompleteStep} onEdit={onEditStep} {...{existentEmail}} />
            <CheckoutStep3 onComplete={onCompleteStep} onEdit={onEditStep} />
          </div>
          <div
            style={{ height: 'max-content' }}
            className="col-span-1 order-first md:order-none gdShadow rounded bg-snow p-4"
          >
            <h3>Order Summary</h3>
            <span className="leading-10">1 Demographics Report</span>
            <div className="text-right">
              <div className="flex flex-row justify-end leading-8">
                <span className="flex-1">Subtotal:</span>
                <div style={{ flex: 0.5 }}>{summaryData.subtotal}</div>
              </div>
              <div className="flex flex-row justify-end leading-8">
                <span className="flex-1">Taxes:</span>
                <div style={{ flex: 0.5 }}>{summaryData.tax}</div>
              </div>
              <div className="flex flex-row justify-end leading-8">
                <span className="flex-1">Total:</span>
                <div className="font-bold text-primary" style={{ flex: 0.5 }}>
                  {summaryData.total}
                </div>
              </div>
            </div>
            <button
              className="btn-primary mx-auto block mt-4"
              disabled={!canCheckout || loading || error}
              onClick={doCheckout}
            >
              <img
                src="/img/lock-locked.svg"
                alt="Checkout"
                className="mr-2 inline-block mb-1"
              />
              <span className="text-white">Confirm Purchase</span>
            </button>
            {error && (
              <span className="text-xs text-error">{error}</span>
            )}
          </div>
        </div>
      </div>
    </CheckoutContext.Provider>
  )
}

// Just to be able to access useStripe, wrap the view in stripe.Elements context
const BuyStripeWrapper = props => (
  <Elements stripe={stripePromise}>
    <Buy {...props} />
  </Elements>
)

export default BuyStripeWrapper
