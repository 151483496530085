/**
 * Events come from https://developers.google.com/gtagjs/reference/event
 */

export const EVENTS = [
  "add_payment_info",
  "add_to_cart",
  "add_to_wishlist",
  "begin_checkout",
  "checkout_progress",
  "exception",
  "generate_lead",
  "login",
  "page_view",
  "purchase",
  "refund",
  "remove_from_cart",
  "screen_view",
  "search",
  "select_content",
  "set_checkout_option",
  "share",
  "sign_up",
  "timing_complete",
  "view_item",
  "view_item_list",
  "view_promotion",
  "view_search_results",
];

/**
 * @example
 * Gtag.events.ADD_PAYMENT_INFO(payloadEvent)
 */

class GtagEvents {
  constructor() {
    this.events = {};

    for (const event of EVENTS) {
      const upperNameEvent = event.toUpperCase();

      this.events[upperNameEvent] = (...args) => {
        return this.event(event, ...args);
      };
    }
  }

  event() {
    throw new Error("Missing event method implementation");
  }
}

export default GtagEvents;
