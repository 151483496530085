import React from 'react'
import Modal from 'react-modal'

Modal.setAppElement('#root')

const CSVHelpModal = ({ countryCode, visible, onClose }) => {
  return (
    <Modal
      isOpen={visible}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onClose}
      className="gdModal"
      overlayClassName="gdModalOverlay"
    >
      <div>
        <div className="p-3 leading-relaxed bg-strongBlack flex flex-row justify-between">
          <span className="text-white font-bold text-xl">CSV File Set-up</span>
          <img className="cursor-pointer" src="/img/close.svg" alt="Close" onClick={onClose} />
        </div>
        <div className="m-4">
          <p>In order to create a report using your CSV file, each line in the first column must contain a postal code and the second column must contain the corresponding frequency.</p>
          <ul className="text-sm text-softBlack mt-2">
            <li>Repeated postal codes will be accumulated and totalized automatically.</li>
            <li>Header columns are not necessary as they will be omitted.</li>
            {countryCode === 'CA' && <li>Postal codes may be any combination or uppercase and lowercase letters.</li>}
            <li>Frequency must be present and it has to be a positive integer.</li>
            <li>Any combination of the following formats is allowed:</li>
          </ul>

          <img className="m-auto my-6" src={`/img/csv-example-${countryCode}.svg`} alt="CSV file formatting example" />

          <button className="btn-primary m-auto block w-28 mb-6" onClick={onClose}>Got it!</button>
        </div>
      </div>
    </Modal>
  )
}

export default CSVHelpModal
