import React from 'react'
import GtagLink from 'components/GtagLink'

const Hero = () => {
  return (
    <div className="section hero flex flex-col justify-center">
      <div className="grid grid-cols-1 md:grid-cols-2 m-auto overflow-hidden">
        <div className="p-7 flex flex-col justify-center">
          <h1>Generate Rich Demographics Reports On-Demand</h1>
          <p className="mt-2 mb-8">Simply upload a CSV containing ZIP or Postal Codes and see your data come to life</p>
          <div className="flex flex-row">
            <GtagLink id="upload-btn-hero" to="/checkout" gtagLabel="Upload click: Hero">
              <button className="btn-primary">Upload now!</button>
            </GtagLink>
            <a href="/Demographics Sample.zip" download>
              <button className="btn-outline ml-7">Download Example</button>
            </a>
          </div>
        </div>

        <div className="px-10 mt-auto">
          <img
            className="max-h-full mx-auto"
            alt="Demographics Reports"
            src="/img/hero-image.png"
          />
        </div>
      </div>
      <div className="w-full md:w-1/2 h-16 flex-shrink-0 flex flex-col items-center ">
        <span>Scroll for more!</span>
        <img
          className="w-4 bouncing scroll-icon"
          alt="Chevrons"
          src="/img/chevrons-down.svg"
        />
      </div>
    </div>
  )
}

export default Hero
