import React, { useState } from 'react'
import { Formik, Field } from 'formik'
import Input from 'formikControls/input'
import CollapsiblePanel from 'components/CollapsiblePanel'
import CSVDropParser from 'components/CSVDropParser'
import CSVHelpModal from './CSVHelpModal'
import { firstStepValidationSchema } from './validationSchemas'
import { isEmpty } from 'helpers/js'
import PropTypes from 'prop-types'

const INITIAL_VALUES = {
  country: '',
  csvData: [],
  fileName: '',
  reportName: '',
}

const CheckoutStep1 = ({ onComplete, onEdit }) => {
  const [csvErrors, setCsvErrors] = useState(null)
  const [showHelpModal, setShowHelpModal] = useState(false)
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES)

  const onEditClick = newInitialValues => {
    setInitialValues(newInitialValues)
    onEdit(1)
  }

  const openHelpModal = () => {
    setShowHelpModal(true)
  }

  const closeHelpModal = () => {
    setShowHelpModal(false)
  }

  const onSubmit = values => {
    onComplete(1, values)
  }

  return (
    <CollapsiblePanel
      title="1. Upload your CSV file"
      stepNumber={1}
      completedKeys={{ country: 'Country:', fileName: 'File:' }}
      onEditClick={onEditClick}
    >
      <div className="flex flex-col">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={firstStepValidationSchema}
          validateOnMount
        >
          {({ values, handleSubmit, isValid, setFieldValue, errors }) => {
            const resetCsv = e => {
              setFieldValue('csvData', null, true)
              setFieldValue('fileName', null, true)
              setCsvErrors(null)
            }

            return (
              <form onSubmit={handleSubmit}>
                <CSVHelpModal
                  visible={showHelpModal}
                  countryCode={values.country}
                  onClose={closeHelpModal}
                />
                <Input
                  type="text"
                  name="reportName"
                  label="Name for your report"

                />
                <label id="country-label" className="mb-1">
                  Which country do you need demographics for?
                </label>
                <div
                  className="mb-2"
                  role="group"
                  aria-labelledby="country-label"
                  onChange={resetCsv}
                >
                  <label className="mr-8 font-normal">
                    <Field type="radio" name="country" value="US" />
                    {'  United States'}
                  </label>
                  <label className="font-normal">
                    <Field type="radio" name="country" value="CA" />
                    {'  Canada'}
                  </label>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 mt-8 mb-2">
                  <label>Upload your CSV File</label>
                  {values.country && (
                    <span
                      className="pt-2 text-left sm:text-right text-sm text-primary cursor-pointer"
                      onClick={openHelpModal}
                    >
                      How should my CSV file be formatted?
                    </span>
                  )}
                </div>
                <CSVDropParser
                  countryCode={values.country}
                  rows={values.csvData}
                  errors={csvErrors}
                  onChange={(data, fileName, errors) => {
                    setFieldValue('csvData', data, true)
                    setFieldValue('fileName', fileName, true)
                    setCsvErrors(errors)
                  }}
                  onReset={resetCsv}
                  onShowHelpModal={openHelpModal}
                />
                <div className="text-center mt-4">
                  <button
                    type="submit"
                    className="btn-primary px-10"
                    disabled={!isValid}
                  >
                    Continue
                  </button>
                </div>
                { csvErrors && !isEmpty(values.csvData) &&
                <div className="text-center mt-2">
                  <span className="text-sm">Only the successfully processed {values.country === 'US' ? 'ZIP' : 'postal' } codes will be uploaded.</span>
                  </div>
                }
              </form>
            )
          }}
        </Formik>
      </div>
    </CollapsiblePanel>
  )
}

CheckoutStep1.propTypes = {
  onComplete: PropTypes.func,
  onEdit: PropTypes.func,
}

export default CheckoutStep1
