import { fetch } from 'whatwg-fetch'

const API_URL = process.env.REACT_APP_API_URL

const buildUrl = path => `${API_URL}${path}`

// Default export object
const api = {}

export const createPaymentSession = async data => {
  try {
    const response = await fetch(buildUrl('/payment/createSession/'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })

    return await response.json()
  } catch ({ message }) {
    return { error: message }
  }
}

export const uploadCSV = async (orderNumber, data) => {
  try {
    const response = await fetch(buildUrl('/uploadCSV/'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({...data, orderNumber}),
    })

    return await response.json()
  } catch (e) {
    return { error: 'There was an error while trying to upload your file data' }
  }
}


export const confirmOrder = async (sessionId, orderNumber, returning) => {
  try {
    const response = await fetch(buildUrl('/payment/confirm/'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ sessionId, orderNumber, returning }),
    })
    return await response.json()
  } catch ({ message }) {
    return { error: message }
  }
}

export const customerExists = async(email) => {
  try {
    const response = await fetch(buildUrl('/user/exists/'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
    return await response.json()
  } catch ({ message }) {
    return { error: message }
  }
}

// Build default export object
// api.createPaymentIntent = createPaymentIntent
// api.paymentConfirmed = paymentConfirmed
api.createPaymentSession = createPaymentSession
api.uploadCSV = uploadCSV
api.confirmOrder = confirmOrder
api.customerExists = customerExists

export default api
