import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Gtag } from "helpers/Gtag";

/**
 * @function getHref
 * @description get href from "to" react-router-dom Link param
 * @param {String|Object} to - Representation of the Link location
 * @returns {String} - Like /some-path/#some-id?search=some-search
 */
function getHref(to) {
  // TODO support "to" param as function - See Link documentation
  if (typeof to === "string") {
    return to;
  }

  if (typeof to === "object" && to !== null) {
    const { pathname, search = "", hash = "" } = to;
    return `${pathname}/${hash}${search}`.replace("//", "/");
  }
}

const GtagLink = ({ children, to, gtagLabel, href, ...rest }) => {
  const _href = href || getHref(to);

  function linkEvent() {
    if (_href) {
      const from = window?.location?.href;
      Gtag.events.LINK_CLICK({ from, to: _href, label: gtagLabel });
    }
  }

  function handleAnchorClick(e) {
    if (rest.onClick) {
      rest.onClick(e);
    }
    linkEvent();
  }

  if (href) {
    return (
      <a href={_href} {...rest} onClick={handleAnchorClick}>
        {children}
      </a>
    );
  }

  return (
    <Link {...rest} to={to}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          onClick: (...arg) => {
            if (child.props.onClick) {
              child.props.onClick(...arg);
            }
            linkEvent();
          },
        })
      )}
    </Link>
  );
};

GtagLink.propTypes = {
  children: PropTypes.any.isRequired,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func])
    .isRequired,
  href: PropTypes.string, // Use "href" prop to force to render a simple anchor
  gtagLabel: PropTypes.string.isRequired,
};

export default GtagLink;
