export const KEY_INSIGHTS = [
  {
    icon: '/img/house-icon.svg',
    title: 'Age, Size, Type of Household',
    description:
      'Understand if you are truly reaching your target audience. How old are your attendees? Do they live alone or married couples living in townhouses they own?',
  },
  {
    icon: '/img/dollar-icon.svg',
    title: 'Income, Education & Occupation',
    description:
      'Is your product targeted at students, postgraduates, or medical professionals? Does their income bracket match your ideal customer?',
  },
  {
    icon: '/img/car-icon.svg',
    title: 'Commute & Household Vehicles',
    description:
      'Are your auto show attendees old enough to drive? Do they currently own a vehicle? What is their main current mode of transportation?',
  },
  {
    icon: '/img/language-icon.svg',
    title: 'Race, Languages Spoken',
    description:
      'Targeted events merit specific data sets. Running an event in Little Italy and curious as to how many attendees spoke Italian?',
  },
]
