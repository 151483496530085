import React from 'react'

const TOS = () => {
  return (
    <div className="section">
      <h1 className="text-center">Website Terms and Conditions of Use</h1>

      <ol>
        <li className="mt-8">1. Terms</li>
        <p>
          By accessing this Website, accessible from
          https://getdemographics.com/, you are agreeing to be bound by these
          Website Terms and Conditions of Use and agree that you are responsible
          for the agreement with any applicable local laws. If you disagree with
          any of these terms, you are prohibited from accessing this site. The
          materials contained in this Website are protected by copyright and
          trade mark law.
        </p>

        <li className="mt-8">2. Use License</li>
        <p>
          Permission is granted to temporarily download one copy of the
          materials on LiveGauge's Website for personal, non-commercial
          transitory viewing only. This is the grant of a license, not a
          transfer of title, and under this license you may not: modify or copy
          the materials; use the materials for any commercial purpose or for any
          public display; attempt to reverse engineer any software contained on
          LiveGauge's Website; remove any copyright or other proprietary
          notations from the materials; or transferring the materials to another
          person or "mirror" the materials on any other server. This will let
          LiveGauge to terminate upon violations of any of these restrictions.
          Upon termination, your viewing right will also be terminated and you
          should destroy any downloaded materials in your possession whether it
          is printed or electronic format. These Terms of Service has been
          created with the help of the Terms Of Service Generator and the
          Privacy Policy Generator.
        </p>

        <li className="mt-8">3. Disclaimer</li>
        <p>
          All the materials on LiveGauge’s Website are provided "as is".
          LiveGauge makes no warranties, may it be expressed or implied,
          therefore negates all other warranties. Furthermore, LiveGauge does
          not make any representations concerning the accuracy or reliability of
          the use of the materials on its Website or otherwise relating to such
          materials or any sites linked to this Website.
        </p>

        <li className="mt-8">4. Limitations</li>
        <p>
          LiveGauge or its suppliers will not be held accountable for any
          damages that will arise with the use or inability to use the materials
          on LiveGauge’s Website, even if LiveGauge or an authorized
          representative of this Website has been notified, orally or written,
          of the possibility of such damage. Some jurisdiction does not allow
          limitations on implied warranties or limitations of liability for
          incidental damages, these limitations may not apply to you.
        </p>
        <li className="mt-8">5. Revisions and Errata</li>
        <p>
          The materials appearing on LiveGauge’s Website may include technical,
          typographical, or photographic errors. LiveGauge will not promise that
          any of the materials in this Website are accurate, complete, or
          current. LiveGauge may change the materials contained on its Website
          at any time without notice. LiveGauge does not make any commitment to
          update the materials.
        </p>
        <li className="mt-8">6. Links</li>
        <p>
          LiveGauge has not reviewed all of the sites linked to its Website and
          is not responsible for the contents of any such linked site. The
          presence of any link does not imply endorsement by LiveGauge of the
          site. The use of any linked website is at the user’s own risk.
        </p>

        <li className="mt-8">7. Site Terms of Use Modifications</li>
        <p>
          LiveGauge may revise these Terms of Use for its Website at any time
          without prior notice. By using this Website, you are agreeing to be
          bound by the current version of these Terms and Conditions of Use.
        </p>
        <li className="mt-8">8. Your Privacy</li>
        <p>Please read our Privacy Policy.</p>
        <li className="mt-8">9. Governing Law</li>
        <p>
          Any claim related to LiveGauge's Website shall be governed by the laws
          of us without regards to its conflict of law provisions.
        </p>
        <li className="mt-8">10. Billing Terms</li>
        <p>
          LiveGauge has partnered with Stripe to process payments on
          GetDemographics.com. If the customer is not satisfied with their
          purchase they will have a 24 hour window to claim a full refund or
          credit. Our refund or credit policy is available for first time users
          and customers deemed to use this policy in a fraudulent and abusive
          way may be identified and prevented from processing further orders.
        </p>
        <li className="mt-8">10. Data Accuracy and Risks</li>
        <p className="mb-20">
          LiveGauge uses the latest data from the US Census, Stats Can, and many
          other current sources to generate and deliver your demographics
          reports. The customer assumes and accepts all risks and responsibility
          associated with the accuracy of their report when purchasing and
          receiving their report. LiveGauge uses a powerful backend algorithm to
          generate the most accurate Demographics report possible that is
          unmatched and constantly being improved daily via our big data machine
          learning algorithms. If for any reason the customer would like to
          dispute their report, we reserve the right to provide troubleshooting
          support from our engineering and data science team to rectify and
          correct any inaccuracies prior to issuing any credits or refunds.
        </p>
      </ol>
    </div>
  )
}

export default TOS
