const OBJECT_CONSTRUCTOR = {}.constructor
const MAP_CONSTRUCTOR = new Map().constructor
const SET_CONSTRUCTOR = new Map().constructor

export const isObject = value => {
  return value && value.constructor === OBJECT_CONSTRUCTOR
}

export const isMap = value => {
  return value && value.constructor === MAP_CONSTRUCTOR
}

export const isSet = value => {
  return value && value.constructor === SET_CONSTRUCTOR
}

export const isArray = value => {
  return Array.isArray(value)
}

export const isNonEmptyArray = value => {
  return Array.isArray(value) && value.length > 0
}

export const isFunction = value => {
  return typeof value === 'function'
}

export const isNumber = value => {
  return typeof value === 'number' && !isNaN(value)
}

export const isPositiveNumber =  value => isNumber(value) && value > 0

export const isString = value => {
  return typeof value === 'string'
}

export const getType = value => {
  if (value === null) {
    return 'null'
  }

  if (isObject(value)) {
    return 'object'
  }

  if (isArray(value)) {
    return 'array'
  }

  if (isFunction(value)) {
    return 'function'
  }

  if (isNumber(value)) {
    return 'number'
  }

  if (isString(value)) {
    return 'string'
  }

  return undefined
}

/**
 * "Empty" means:
 *   . undefined
 *   . null
 *   . ''
 *   . 0
 *   . {}
 *   . []
 */
export const isEmpty = value => {
  if (!value) {
    return true
  }

  if (isArray(value)) {
    return value.length === 0
  }

  if (isObject(value)) {
    return Object.keys(value).length === 0
  }

  return false
}

export const isUndefined = value => {
  return typeof value === 'undefined'
}
