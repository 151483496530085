import { GA4Manager } from "src/lib";

const isProd = process.env.NODE_ENV === "production";

const REACT_APP_GOOGLE_ANALYTICS_KEY =
  (isProd && process.env.REACT_APP_GOOGLE_ANALYTICS_KEY) ||
  "GOOGLE_ANALYTICS_KEY";

const Gtag = new GA4Manager(REACT_APP_GOOGLE_ANALYTICS_KEY, {
  debug: !isProd,
});

export { Gtag };
