import React from 'react'
import GtagLink from 'components/GtagLink'

const QualityDemographics = () => (
  <div className="bg-snow w-full py-14">
    <div className="section">
      <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
        <div className="md:col-span-7">
          <img src="/img/insights.png" alt="QualityDemographics" />
        </div>
        <div className="md:col-span-5 flex flex-col items-center justify-center text-center">
          <h3>Turn Raw Data into Actionable Insights with quality demographics</h3>
          <p className="mt-4">Dig deeper than basic Lead Capture and Brand Ambassador submissions. Amplify your brand’s competitive advantages and the value of your raw data by converting it into actionable information.</p>
          <GtagLink id="upload-btn-qd" to="/checkout" gtagLabel="Upload click: Quality Demographics">
            <button className="btn-primary">Upload now!</button>
          </GtagLink>
        </div>
      </div>
    </div>
  </div>
)

export default QualityDemographics
