/**
 * Custom events doesn't come with Gtag events
 * and these should be added manually in your dashboard.
 */
import GtagEvents from "./GtagEvents";

class CustomEvents extends GtagEvents {
  constructor(...args) {
    super(...args);
    this.trackLinkStorage = {};
    this.onReady = Promise.resolve();
    this.addCustomEvents();
  }

  addCustomEvents = () => {
    // Register new custom events here
    this.events.LINK_CLICK = this.linkClick.bind(this);
    this.events.CHECKOUT = this.checkout.bind(this);
  };

  /**
   * @param {Object} params
   * @param {String} params.label
   * @param {String} params.from - current URL
   * @param {String} params.to - link href
   */
  linkClick({ label, from, to }) {
    return this.onReady.then(() =>
      this.event("link_click", { label, from, to })
    );
  }

  /**
   * @method checkout
   * @description To track checkout process
   * @param {String} productId - Product name or ID
   * @param {String} status - one of: INIT | IN_PROGRESS | SUMMARY | FINISHED | ERROR
   * @param {Object} checkoutInfo - Use to storage any kind of data about the checkout
   * @returns
   */
  checkout(productId, status, checkoutInfo) {
    return this.onReady.then(() =>
      this.event("checkout", {
        productId,
        status,
        ...checkoutInfo,
      })
    );
  }
}

export default CustomEvents;
