import React, { Fragment } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import usePageView from "src/hooks/usePageView";
import Navbar from "components/Navbar";
import Home from "containers/Home";
import Buy from "containers/Buy";
import CheckoutResult from "containers/CheckoutResult";
import TOS from "containers/TOS";
import PrivacyPolicy from "containers/PrivacyPolicy";
import Footer from "components/Footer";

function Routes() {
  usePageView();

  return (
    <Fragment>
      <Navbar />
      <Switch>
        <Route path="/checkout/:orderNumber">
          <CheckoutResult />
        </Route>
        <Route path="/checkout">
          <Buy />
        </Route>
        <Route path="/tos">
          <TOS />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
      <Footer />
    </Fragment>
  );
}

function App() {
  return (
    <Router>
      <Routes />
    </Router>
  );
}

export default App;
