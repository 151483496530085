export const PRICING_FEATURES = [
  [
    'Single file Upload',
    'Access to Visual Dashboard',
    'Single User Log-in for Dashboard',
    'Instant Report Download',
  ],
  [
    'Volume-Based Pricing',
    'Custom Solutions',
    'Add-ons Avaliable',
    'Multiple User Log-ins',
    'Multiple File Support',
  ],
]
