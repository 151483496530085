import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import GtagLink from 'components/GtagLink'

const Navbar = () => {
  const location = useLocation()
  const hideButtons = location.pathname.indexOf('/checkout') > -1

  return (
    <div className="section w-full flex flex-row flex-shrink-0 items-center h-28">
      <Link to="/">
        <img src="/img/logo-light.svg" alt="Get Demographics" />
      </Link>
        <div className="flex flex-1 flex-row items-center justify-end">
          {
            hideButtons ?
            <div className="flex items-end h-16">
              <Link className="text-xs text-softBlack" to="/">&lsaquo; Return to Website</Link>
            </div> :
            <>
              <GtagLink id="nav-how" href="/#how-it-works" gtagLabel="Nav: How it works">
                <button className="btn-link mx-2">How It works</button>
              </GtagLink>
              <GtagLink id="nav-price" href="/#pricing" gtagLabel="Nav: Pricing">
                <button className="btn-link mx-2">Pricing</button>
              </GtagLink>
              <GtagLink id="nav-faq" href="/#faq" gtagLabel="Nav: FAQ">
                <button className="btn-link mx-2">FAQ</button>
              </GtagLink>
              <GtagLink id="upload-btn-nav" to="/checkout" gtagLabel="Upload click: Navigation">
                <button className="btn-primary ml-2">Upload now!</button>
              </GtagLink>
            </>
          }
        </div>
    </div>
  )
}

Navbar.propTypes = {}

export default Navbar
