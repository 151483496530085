export const FAQ = [
  {
    title: 'How long does it take to generate a report?',
    description:
      'Demographics turnaround happens within minutes of your submission. Once you submit your document simply look to your email for log-in credentials to access your report.',
  },
  {
    title: 'What method payments do you accept?',
    description: 'Visa, MasterCard, and American Express',
  },
  {
    title: 'What regions do you support?',
    description:
      'Currently GetDemographics is only available for data sets from Canada and the USA.',
  },
  {
    title: 'How do you get your data?',
    description:
      'Over the years as phones connect to public WiFi networks their data is captured and stored in public databases. Over the past several years data teams have correlated the data allowing firms to develop unique insights.',
  },
  {
    title: 'What information is available for Zip Codes?',
    description:
      'An American Demographics report will include: Age of Household Maintainer, Size of Household, Family Dwelling or Non-Family Household, Housing Tenure, Housing Type, Housing Period of Construction, Housing Vehicles Available, Household Income, Education, Labour Force (16+), Labour Force by Occupation, Commuting, Language Spoken, Race',
  },
  {
    title: 'What information is available for Postal Codes?',
    description:
      'A Canadian Demographics report will include: Age of Household Maintainer, Size of Household, Household Type, Marital status, Housing Tenure, Housing Type, Housing period of Construction, Household Income, Education, Labour force (15+), Labour Force By Occupation, Commuting, Knowledge of Official Language, Immigration status, Visible minority status, Mother Tongue',
  },
]
