import { object, string, array } from 'yup'
import { ZIPCODE_VALIDATION_REGEX } from 'helpers/regex'

export const firstStepValidationSchema = object().shape({
  country: string().required('This field is required'),
  csvData: array().required('You must select a file with valid data').min(1, 'Data is required'),
  reportName: string().required('Please enter a name for your report'),
})

export const secondStepValidationSchema = object().shape({
  firstName: string().required('Please enter your first name'),
  lastName: string().required('Please enter your last name'),
  company: string(),
  usage: string(),
  industry: string(),
})

export const thirdStepValidationSchema = object().shape({
  fullName: string().required('Required'),
  address: string().required('A billing address is needed'),
  country: string().required('Please select a country'),
  state: string().required('This value is required').when('country', {
    is: 'CA',
    then: string().required('Please select a state')
  }),
  city: string().required('Required'),
  postalCode: string().required('This value is required').when('country', {
    is: 'CA',
    then: string().transform(v => v ? v.toUpperCase() : v).matches(ZIPCODE_VALIDATION_REGEX.CA, 'Please enter a valid Canadian postal code'),
  })
})

export const isValidEmail = email => email && object().shape({email: string().email()}).isValidSync({email})