import React from 'react'
import { KEY_INSIGHTS } from 'data'

const KIItem = ({ item }) => {
  return (
    <div className="gdShadowedBox">
      <div className="flex flex-row">
        <div className="flex-shrink-0 w-14 flex items-center">
          <img src={item.icon} alt={item.title} />
        </div>
        <div className="flex items-center text-strongBlack font-bold text-xl">
          {item.title}
        </div>
      </div>
      <p className="mt-5">{item.description}</p>
    </div>
  )
}

const KeyInsights = () => {
  return (
    <div className="section flex flex-col items-center py-12">
      <h2>Key Insights</h2>
      <div className="title-underline w-28" />
      <p className="text-xl mb-10 mt-4">
        Use <span className="text-primary font-bold">GetDemographics</span> to
        gain key insights from your raw data.
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {KEY_INSIGHTS.map((item, ix) => (
          <KIItem key={`kii-${ix}`} item={item} />
        ))}
      </div>
      <p className="mt-10 text-center">
        Data metrics vary between Zip Codes and Postal Code uploads, please
        visit the FAQ’s for a detailed breakdown.
      </p>
    </div>
  )
}

export default KeyInsights
