import React from 'react'
import GtagLink from 'components/GtagLink'

const TryItNow = () => {
  return (
    <div className="w-full" style={{ backgroundColor: '#FDCEB6' }}>
      <div className="section text-center py-14">
        <div className="flex flex-row">
          <div className="hidden md:block w-40 mx-8">
            <img src="/img/try-now-1.svg" alt="Try Demographics Now!" />
          </div>
          <div>
            <h2>Turn your Raw Data into Actionable Insights</h2>
            <p className="my-4">
              Upload your CSV file and gain access to power demographics to
              better understand your audience.{' '}
            </p>
            <GtagLink id="upload-btn-insights" to="/checkout" gtagLabel="Upload click: Actionable Insights">
              <button className="mt-4 btn-primary">Try it now!</button>
            </GtagLink>
          </div>
          <div className="hidden md:block w-40 mx-6 mt-8">
            <img src="/img/try-now-2.svg" alt="Try Demographics Now!" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TryItNow
