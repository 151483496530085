export const HOW_IT_WORKS = [
  {
    number: 1,
    title: 'Simply upload a CSV with Zip Codes or Postal Codes',
    description:
      'Upload a CSV file with ZIP or Postal codes of your target audiences and the frequency of each.',
    image: 'upload-now.svg',
    imgAlt: 'CSV Upload',
  },
  {
    number: 2,
    title: 'Data is processed and a report is generated within seconds!',
    description:
      'Once your file is uploaded, our powerful data engine will process your raw data in a matter of seconds and create a demographics reports about your target audience.',
    image: 'gears.svg',
    imgAlt: 'Demographic report',
  },
  {
    number: 3,
    title: 'Access the powerful Dashboard to view and analyze your data!',
    description:
      'Watch your raw data come to life in our dynamic dashboard at LiveGauge. View your demographics in an easy to consume visual report or download your report in an excel file.',
    image: 'powerful-dashboard.svg',
    imgAlt: 'Powerful dashboard',
  },
]
