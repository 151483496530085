import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import Papa from 'papaparse'
import { isEmpty } from 'helpers/js'
import { ZIPCODE_VALIDATION_REGEX } from 'helpers/regex'
import PropTypes from 'prop-types'

const ResultMessage = ({ rows, errors, onShowHelpModal }) => {
  const renderItems = []
  const isError = isEmpty(rows)
  const helpLink = (
    <span onClick={onShowHelpModal} className="text-primary cursor-pointer">
      these instructions
    </span>
  )
  if (!isError) {
    renderItems.push(
      <div key="rm-1" className="text-sm">
        <img
          className="inline w-4 h-4 mb-1"
          src="/img/green-check.svg"
          alt="Processed lines"
        />
        <span>{` ${rows.length} lines successfully processed`}</span>
      </div>
    )
  }

  if (!isEmpty(errors)) {
    if (isError) {
      renderItems.push(
        <div key="rm-2" className="text-sm text-softBlack">
          <img
            className="inline w-4 h-4 mb-1"
            src="/img/exclamation-error.svg"
            alt="Error lines"
          />
          <span> We were unable to process your CSV file.</span>
          <div className="ml-5">
            Please double check your file for any errors. Follow {helpLink} to
            see how your file should be formatted.
          </div>
        </div>
      )
    } else {
      renderItems.push(
        <div key="rm-3" className="text-sm text-softBlack">
          <img
            className="inline w-4 h-4 mb-1"
            src="/img/exclamation-warning.svg"
            alt="Error lines"
          />
          <span>{` ${errors.length} could not be processed`}</span>
          <div className="ml-5">
            The following lines had incorrect formatting. If you wish to fix
            these lines, follow {helpLink} and re-upload your file.
          </div>
        </div>
      )
    }
  }

  if (errors && errors.length) {
    renderItems.push(
      <div key="rm-4" className={`${isError ? 'bg-errorLight border-error' : 'border-warning bg-warningLight'} border flex flex-col p-4 mt-1 overflow-y-scroll max-h-28`}>
        <code className="text-xs whitespace-pre-wrap text-strongBlack">
          {errors.join('\n')}
        </code>
      </div>
    )
  }
  return renderItems
}

const CSVDropParser = ({
  rows,
  errors,
  countryCode,
  onChange,
  onReset,
  onShowHelpModal,
}) => {
  const disabled = !Boolean(countryCode)
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0]
      const parsed = {
        records: {},
        errors: [],
      }

      if (!file) {
        return null
      }

      Papa.parse(file, {
        header: false,
        complete: (results) => {
          const zipcodeValidation = new RegExp(
            ZIPCODE_VALIDATION_REGEX[countryCode]
          )

          results.data.forEach((row, index) => {
            const [zip = '', occurrences = ''] = row
            // transform every character that is not a digit or number to a dash
            // applies for both US and canada
            const zipcode = zip
              .toUpperCase()
              .trim()
              .replace(/[^a-zA-Z0-9]/g, '-')
            const value = parseInt(occurrences, 10)
            let errorMessage = null

            if (!row || row.length < 2) {
              errorMessage = `Line ${index + 1}: invalid line format "${row}"`
            } else if (!zipcodeValidation.test(zipcode)) {
              errorMessage = `Line ${
                index + 1
              }: invalid ${countryCode === 'US' ? 'zip' : 'postal'} code "${zip.trim()}"`
            } else if (isNaN(value) || value < 1) {
              errorMessage = `Line ${
                index + 1
              }: invalid frequency "${occurrences.trim()}"`
            }

            if (errorMessage === null) {
              parsed.records[zipcode] = (parsed.records[zipcode] || 0) + value
            } else {
              parsed.errors.push(errorMessage)
            }
          })

          const parsedRows = Object.keys(parsed.records).map((zip) => [
            zip,
            parsed.records[zip],
          ])

          onChange(
            parsedRows,
            file.path,
            !isEmpty(parsed.errors) ? parsed.errors : null
          )
        },
      })
      // eslint-disable-next-line
    }, [countryCode, onChange])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled,
    maxFiles: 1,
  })

  if (isEmpty(rows) && isEmpty(errors)) {
    const dropzoneText = disabled
      ? 'Please Select a Country First'
      : 'Drop a file here, or click to select files'
    const cursorClass = disabled ? 'cursor-not-allowed' : 'cursor-pointer'

    return (
      <div
        className={`p-10 border-2 border-primary border-dashed outline-none text-center ${cursorClass}`}
        style={{ opacity: disabled ? 0.5 : 1 }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <img
          className="mx-auto mb-4"
          src="/img/upload-cloud.svg"
          alt="Upload"
        />
        <p>{dropzoneText}</p>
      </div>
    )
  }

  return (
    <div className="w-full">
      <div className="max-h-60 overflow-y-scroll mb-2">
        <table className="border border-rawBlack border-collapse text-strongBlack mx-auto w-full bg-lightGray">
          <tbody>
            <tr>
              <th className="px-4 font-normal border border-rawBlack">
                {countryCode === 'US' ? 'ZIP Code' : 'Postal Code'}
              </th>
              <th className="px-4 font-normal border border-rawBlack">
                Frequency
              </th>
            </tr>
            {rows &&
              rows.map(([zip, occurrences], index) => {
                return (
                  <tr key={`csvrow-${index}`}>
                    <td className="px-4 text-center border border-rawBlack">
                      {zip}
                    </td>
                    <td className="px-4 text-center border border-rawBlack">
                      {occurrences}
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
      <ResultMessage
        rows={rows}
        errors={errors}
        onShowHelpModal={onShowHelpModal}
      />
        <span
          className="text-xs mt-2 text-left sm:text-right text-primary cursor-pointer"
          onClick={onReset}
        >
          Click here to replace your file
        </span>
    </div>
  )
}

CSVDropParser.propTypes = {
  disabled: PropTypes.bool.isRequired,
}

CSVDropParser.defaultProps = {
  disabled: false,
}

export default CSVDropParser
