import mixpanel from 'mixpanel-browser'

const REACT_APP_MIXPANEL_KEY = process.env.REACT_APP_MIXPANEL_KEY

export const init = () => {
  mixpanel.init(REACT_APP_MIXPANEL_KEY)

  mixpanel.track_links('#upload-btn-nav', 'Upload click: Navigation')
  mixpanel.track_links('#upload-btn-hero', 'Upload click: Hero')
  mixpanel.track_links('#upload-btn-qd', 'Upload click: Quality Demographics')
  mixpanel.track_links('#upload-btn-pricing', 'Upload click: Pricing')
  mixpanel.track_links('#upload-btn-insights', 'Upload click: Actionable Insights')
  mixpanel.track_links('#nav-how', 'Nav: How it works')
  mixpanel.track_links('#nav-price', 'Nav: Pricing')
  mixpanel.track_links('#nav-faq', 'Nav: FAQ')
}

export const track = (name, data) => {
  try {
    mixpanel.track(name, data)
  } catch (error) {
    console.error('Mixpanel logging error')
    console.error(error.message)
  }
}

const mixpanelHelper = {
  init,
  track,
}

export default mixpanelHelper