import React from 'react'
import { useField } from 'formik'

const Select = ({
  className,
  label,
  optional,
  children,
  onChange,
  ...props
}) => {
  const [field, meta] = useField({ ...props })
  let error = null

  if (meta.touched && meta.error) {
    error = meta.error
  }

  const onSelectChange = e => {
    onChange(e.target.value)
    field.onChange(e)
  }

  return (
    <div className={`flex flex-col mb-2 ${className}`}>
      <div className="flex flex-row justify-between items-end mb-1">
        <label htmlFor={props.name}>{label}</label>
        {optional && <span className="text-xs">Optional</span>}
      </div>
      <select
        className="h-10 text-softBlack"
        {...field}
        onChange={onSelectChange}
      >
        {children}
      </select>
      <span className="text-error text-xs h-4">{error}</span>
    </div>
  )
}

Select.defaultProps = {
  className: '',
  optional: false,
  onChange: () => {},
}

export default Select
