import React, { useEffect, useState } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import api from 'services/api'
import mixpanel from 'helpers/mixpanel'
import { Gtag } from 'helpers/Gtag'

const CheckoutResult = () => {
  const { orderNumber } = useParams()
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const query = new URLSearchParams(useLocation().search)
  const sessionId = query.get('sessionId')
  const returningCustomer = query.get('r') === '1'

  useEffect(() => {
    async function confirmOrder() {
      const result = await api.confirmOrder(
        sessionId,
        orderNumber,
        returningCustomer
      )
      if (result.error) {
        setErrorMessage(result.error)
      }
      setLoading(false)
    }

    if (orderNumber && sessionId) {
      setLoading(true)
      confirmOrder()
    }
  }, [orderNumber, sessionId, returningCustomer])

  useEffect(() => {
    mixpanel.track('Checkout Result', { orderNumber, sessionId })

    Gtag.events.CHECKOUT("GET_DEMOGRAPHICS", "FINISHED", { orderNumber, sessionId })
    // eslint-disable-next-line
  }, [])

  if (loading) {
    return (
      <div className="section px-20 my-28 flex flex-col items-center text-center">
        <div className="spinner" />
      </div>
    )
  }

  return (
    <div className="section px-20 my-28 flex flex-col items-center text-center">
      {errorMessage ? (
        <>
          <img src="/img/exclamation.svg" alt="Checkout error" />
          <h3 className="mb-8 mt-4">
            Uh-oh! There was an error processing your checkout.
          </h3>

          <p>{errorMessage}</p>
          <p>
            If the issue persists, please shoot us a line to{' '}
            <a href="mailto:support@getdemographics.com">
              support@getdemographics.com
            </a>
          </p>
        </>
      ) : (
        <>
          <img src="/img/checkout-success.svg" alt="Checkout success!" />
          <h3 className="mb-8 mt-4">{`Your report #${orderNumber} has been processed!`}</h3>
          {returningCustomer ? (
            <>
              <p>Your report is processing!</p>
              <p>
                To view it, access your dashboard at{' '}
                <a className="font-semibold" href="https://cloud.livegauge.com">cloud.livegauge.com</a>.
              </p>
            </>
          ) : (
            <>
              <p>A username and password has been sent to your email.</p>
              <p>
                To view your demographics report go to{' '}
                <a className="font-semibold" href="https://cloud.livegauge.com">cloud.livegauge.com</a>{' '}
                and use the username and password provided.
              </p>
            </>
          )}
        </>
      )}

      <Link className="mt-8" to="/">
        <img
          className="inline-block mr-2"
          src="/img/chevron-left-primary.svg"
          alt="Return to Homepage"
        />
        <span className="text-xs text-primary">Return to Homepage</span>
      </Link>
    </div>
  )
}

export default CheckoutResult
